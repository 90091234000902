export const Skills = [
    'JavaScript',
    'Python',
    'c++',
    'shell/bash',
    'TypeScript',
    'Linux',
    'Git',
    'MongoDB',
    'MariaDB',
    'Postgresql',
    'Node.js',
    'Django',
    'HTML',
    'Css',
    'Bootstrap',
    'materializecss',
    'Material UI',
    'ReactJS',
    'Redux',
    'Redis',
    'Docker',
    'Sockets',
    'Numpy',
    'Pandas',
    'Matplotlib',
    'SciPy',
    'jQuery',
    'PHP'
]