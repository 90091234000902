import React from 'react';
import { Skills } from '../storage/SkillData';

const About = () => {

    const about = `
        Full stack developer specialized in backend development and server side engineering. 
        I have vast experience developing, implementing applications and solutions using various programming languages and technologies. 
        With with more then six years working on fields such as networking, system automation, restful APIS and web design and development.
    `
    // const about = `
    //     Full stack developer specialized in backend development and server side engineering. 
    //     I have vast experience developing, implementing applications and solutions using various programming languages and technologies. 
    //     With great experience on fields such as networking, system automation, restful APIS and web design and development.
    // `

    // const about = `
    //     Full-stack developer with 6+ years of experience building scalable 
    //     web applications and internal tools. In my six years of journey I have 
    //     been learning different technologies including different programming languages, 
    //     networking, system automation, restful APIS and web design and development.
    //     I have worked on some freelance projects.  
    // `

    return (
        <section  className="about" id="About">
            <div className="about__heading">
                <h2 className="about__heading__title">About me.</h2>
                <h2 className="about__heading__empty"></h2>
            </div>
            <div className="about__info">
                <div className="about__info_empty"></div>
                <div className="about__info__desc">
                    <p>{about}</p>
                
                    
                    <h2 className="about_tech__heading">technologys i use.</h2>
                    <div className="about__tech_tags">  
                        {
                            Skills && Skills.map((skill,id) => (
                                <span key={id} className="cutombtntool">{skill}</span>
                            ))
                        }
                    </div>
                </div>
                <div className="about__info_empty_two"></div>
            </div>
        </section>
    );
}

export default About;
