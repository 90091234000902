

import Images from "./Images"





export const ProjectsData = [
    {
        order: '0',
        name: 'pintor',
        image: Images.Pintor,
        link: 'https://pintor.app',
        description: 'web based paint and designing tool for starters.',
        github_link: '',
        youtube_link: 'https://youtu.be/7ewCU1hfrGc',
    },
    {   
        order: '1',
        name: 'catch',
        image: Images.Catch,
        link: 'https://ndotdev-catch.herokuapp.com',
        description: 'web based B2C e-commerce platform where people can sign up as seller or as a customer and buy and sell their trending products',
        github_link: 'https://github.com/nafiu-dev/catch',
        youtube_link: 'https://youtu.be/X-rQrxTLf3Q',
    },
    {   
        order: '3',
        name: 'Hire',
        image: Images.Hire,
        link: 'https://ndotdev-hire.herokuapp.com/',
        description: 'web based job listing platform where users can search and apply for their favorite job or post a new job after signing up as a company',
        github_link: 'https://github.com/nafiu-dev/hire',
        youtube_link: 'https://youtu.be/jrW0CyayrTk',
    },
    {   
        order: '4',
        name: 'Sheld',
        image: Images.Sheld,
        link: 'http://sheld.nafiunizar.com/',
        description: 'web based network scanning tool created using Django which is capable of scaining port numbers, tracing routes, and more',
        github_link: 'https://github.com/nafiu-dev/sheld-security',
        youtube_link: 'https://youtu.be/5wZX-ebueFY',
    },
    {   
        order: '5',
        name: 'Laari',
        image: Images.Laari,
        link: 'https://django-react-bankapp.netlify.app',
        description: 'web based banking platform where users can create accounts per request and users will be able to convert and transfer money between different accounts in the network. (demo user: ross, jhon | password: 123456789 )',
        github_link: 'https://github.com/nafiu-dev/bankapp-laari',
        youtube_link: 'https://youtu.be/H9SXMiA9i58',
    },
    {   
        order: '6',
        name: 'Scan',
        image: Images.Scan,
        link: 'https://gifted-knuth-ff8c50.netlify.app/',
        description: 'web based web scraping tool that looks for best keywords and also check the sites performance, accessibility and seo and more',
        github_link: 'https://github.com/nafiu-dev/scan-seo',
        youtube_link: 'https://youtu.be/WUiIpAsBbv4',
    },
    {   
        order: '2',
        name: 'Sticker',
        image: Images.Sticker,
        link: 'https://sticker.nafiunizar.com',
        description: 'basic web based sticker generating tool',
        github_link: 'https://github.com/nafiu-dev/sticker',
        youtube_link: 'https://youtu.be/fscRfD8K7gs',
    },
]




