import React, {Fragment, useState, useEffect} from 'react';
import About from './components/About';
import Header from './components/Header';
import Home from './components/Home';
import Projects from './components/Projects';

const App = () => {
    const [isAbout, setAbout] = useState(null)
    const [isProjects, setProjects] = useState(null)
    useEffect(() => {
        setAbout(true)
        setProjects(true)
    }, [])

    return (
        <Fragment>
            <div className="border_fixer_top"></div>
            <div className="border_fixer_bottom"></div>
            <Header />
            <Home />
            {/* ABOUT */}
            {
                isAbout ? <About /> : null
            }

            {/* PROJECTS */}
            {
                isProjects ? <Projects /> : null
            }
            
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </Fragment>
    );
}

export default App;
