import Face from './Images/face.png'
import Catch from './Images/catch.png'
import Hire from './Images/hire.png'
import Laari from './Images/laari.png'
import Pintor from './Images/pintor.png'
import Scan from './Images/scan.png'
import Sheld from './Images/sheld.png'
import Sticker from './Images/sticker.png'



const Images = {
    Face, 
    Catch,
    Hire,
    Laari,
    Pintor,
    Scan,
    Sheld,
    Sticker
}
export default Images